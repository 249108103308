import React, { useState } from "react";
import "../style/Form.css";
import {
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import Map from "./Map";

const Form = () => {
  const dataCity = [
    "New York City, USA",
    "Hanoi, Vietnam",
    "London, UK",
    "Paris, France",
    "Los Angeles, USA",
    "Rome, Italy",
    "Beijing, China",
    "Mumbai, India",
    "Sydney, Australia",
    "Cairo, Egypt",
    "Dubai, UAE",
    "Shanghai, China",
    "Istanbul, Turkey",
    "Rio de Janeiro, Brazil",
    "Singapore",
    "Moscow, Russia",
    "Hong Kong, China",
    "Barcelona, Spain",
    "Toronto, Canada",
    "Mexico City, Mexico",
    "Buenos Aires, Argentina",
    "San Francisco, USA",
    "Berlin, Germany",
    "Madrid, Spain",
    "Bangkok, Thailand",
    "Chicago, USA",
    "Melbourne, Australia",
    "Cape Town, South Africa",
    "Seoul, South Korea",
    "Athens, Greece",
    "Lisbon, Portugal",
    "Amsterdam, Netherlands",
    "Jerusalem, Israel",
    "Vancouver, Canada",
    "Jakarta, Indonesia",
    "Kuala Lumpur, Malaysia",
    "São Paulo, Brazil",
    "Miami, USA",
    "Las Vegas, USA",
    "Milan, Italy",
    "Delhi, India",
    "Montreal, Canada",
    "Edinburgh, UK",
    "Dublin, Ireland",
    "Honolulu, USA",
    "Marrakech, Morocco",
    "Florence, Italy",
    "Prague, Czech Republic",
    "Venice, Italy",
    "Vienna, Austria",
    "Zurich, Switzerland",
    "Stockholm, Sweden",
    "Oslo, Norway",
    "Helsinki, Finland",
    "Warsaw, Poland",
    "Budapest, Hungary",
    "Brussels, Belgium",
    "Copenhagen, Denmark",
    "Krakow, Poland",
    "Lima, Peru",
    "Bogota, Colombia",
    "Quito, Ecuador",
    "Cusco, Peru",
    "Santiago, Chile",
    "Montevideo, Uruguay",
    "Caracas, Venezuela",
    "Reykjavik, Iceland",
    "Havana, Cuba",
    "San Juan, Puerto Rico",
    "Kingston, Jamaica",
    "Bridgetown, Barbados",
    "Panama City, Panama",
    "Manila, Philippines",
    "Phuket, Thailand",
    "Kolkata, India",
    "Hyderabad, India",
    "Bangalore, India",
    "Chennai, India",
    "Kathmandu, Nepal",
    "Colombo, Sri Lanka",
    "Male, Maldives",
    "Amman, Jordan",
    "Riyadh, Saudi Arabia",
    "Kuwait City, Kuwait",
    "Doha, Qatar",
    "Muscat, Oman",
    "Tehran, Iran",
    "Karachi, Pakistan",
    "Lahore, Pakistan",
    "Dhaka, Bangladesh",
    "Yangon, Myanmar",
    "Ho Chi Minh City, Vietnam",
    "Siem Reap, Cambodia",
    "Luang Prabang, Laos",
    "Vientiane, Laos",
    "Ulaanbaatar, Mongolia",
    "Tashkent, Uzbekistan",
    "Almaty, Kazakhstan",
    "Bishkek, Kyrgyzstan",
    "Ashgabat, Turkmenistan",
    "Dushanbe, Tajikistan",
    "Baku, Azerbaijan",
    "Yerevan, Armenia",
    "Tbilisi, Georgia",
    "Minsk, Belarus",
    "Belgrade, Serbia",
    "Sofia, Bulgaria",
    "Bucharest, Romania",
    "Zagreb, Croatia",
    "Ljubljana, Slovenia",
    "Sarajevo, Bosnia and Herzegovina",
    "Skopje, North Macedonia",
    "Tirana, Albania",
    "Podgorica, Montenegro",
    "Pristina, Kosovo",
    "Nicosia, Cyprus",
    "Valletta, Malta",
  ];
  const [location, setLocation] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [date, setDate] = useState("");
  const [people, setPeople] = useState("");
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAutoCompleteChange = (event, value) => {
    setLocation(value);
    setInputValue(value || "");
    console.log(value);
  };

  const handleAutoCompleteBlur = () => {
    if (dataCity.includes(inputValue)) {
      setLocation(inputValue);
    }
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
    setLocation(value);
  };

  const handleDate = (event) => {
    setDate(event.target.value);
  };

  const handlePeople = (event) => {
    setPeople(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      location: location,
      dayNum: date,
      type: people,
    };

    console.log("payload", payload);

    setLoading(true);
    setErrorMessage(""); // Reset error message

    fetch(
      "https://pnhr8soo15.execute-api.ap-southeast-2.amazonaws.com/dev/travel",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (
          data.response.Description.toLowerCase().includes("not feasible") ||
          data.response.Description.toLowerCase().includes("in just 1 day") ||
          data.response.Description.toLowerCase().includes("1 day") ||
          data.response.Description.toLowerCase().includes("one day") ||
          data.response.Description.toLowerCase().includes("in just one day")
        ) {
          setErrorMessage(
            "Your requested location is very vibrant and beautiful, it would be best to plan for at least a few days instead of just 1."
          );
          setLoading(false);
        } else if (
          Array.isArray(data.response.Plans) &&
          data.response.Plans.length === 0
        ) {
          setErrorMessage("AI generation failed, please try again.");
          setLoading(false);
        } else {
          setMapData(data);
          setLoading(false);
          setShowForm(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const isFormValid = () => {
    return location && date && people;
  };

  return (
    <>
      {showForm ? (
        <>
          <div className="container">
            <h2>AnhEmTravelling</h2>
            <h1>Where d'you wanna go?</h1>
            <Typography variant="caption">
              Our AI agents will plan for you the best details plan and budget
              for your trip
            </Typography>
            <br />
            <br />

            <form action="" onSubmit={handleSubmit} id="join-us">
              <Autocomplete
                freeSolo
                options={dataCity}
                getOptionLabel={(option) => option}
                value={location}
                onChange={handleAutoCompleteChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Select or manually type your destination"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: "40px",
                          textAlign: "center",
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          textAlign: "center",
                        },
                      }}
                      onBlur={handleAutoCompleteBlur}
                    />
                  </>
                )}
              />
              <span>
                <Select
                  value={date}
                  onChange={handleDate}
                  displayEmpty
                  style={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Number of days
                  </MenuItem>
                  {[...Array(7).keys()].map((day) => (
                    <MenuItem key={day + 1} value={day + 1}>
                      {day + 1}
                    </MenuItem>
                  ))}
                </Select>
              </span>
              <br />
              <br />
              <span>
                <Select
                  value={people}
                  onChange={handlePeople}
                  displayEmpty
                  style={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Solo, Friends, Family
                  </MenuItem>
                  <MenuItem value="Solo">Solo</MenuItem>
                  <MenuItem value="Friends">Friends</MenuItem>
                  <MenuItem value="Family">Family</MenuItem>
                </Select>
              </span>
              <br />
              <br />
              <br />
              <div className="submit">
                <input
                  disabled={loading || !isFormValid()}
                  className="submit"
                  value="Submit"
                  type="submit"
                />
              </div>
            </form>

            {loading && (
              <Box sx={{ marginTop: 15 }}>
                <CircularProgress />
                <br />
                <p style={{ color: "white", fontSize: "24px" }}>
                  Loading. Please wait ...
                </p>
              </Box>
            )}

            {errorMessage && (
              <Box sx={{ marginTop: 5 }}>
                <p style={{ color: "black", fontSize: "18px" }}>
                  {errorMessage}
                </p>
              </Box>
            )}
          </div>
        </>
      ) : (
        <>
          <Map data={mapData} setShowForm={setShowForm} />
        </>
      )}
    </>
  );
};

export default Form;
